.swiper {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-position: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;

  /* Center slide text vertically */
  display: flex;
}




.--swiper-navigation {
  z-index: 999999;
  font-size: 85px;
  position: absolute;
  background-color: aliceblue;
}

.image-container {
  height: 350px;
}

.slider-border {
  border-radius: 10px;
}
