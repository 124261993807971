
.animate-move {
    animation: move 10s linear infinite;
    transition: 5s;
  }
  
  @keyframes move {
  
    0%,
    100% {
      transform: translateY(0);
    }
  
    50% {
      transform: translateY(180px);
    }
  
    70% {
      transform: translateY(120px);
      transform: scale(1.2);
    }
  
  
  }