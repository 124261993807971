.swiper {
  width: 100%;
  
}


.custom-swiper .swiper-wrapper{
  display: flex;
  justify-content: center;
}
.custom-swiper .swiper-slide{
  display: flex;
  justify-content: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  object-fit: cover;
}
