.homeBannerBg1 {
    background-color: rgba(0, 0, 255, 0.4);
}

.homeBannerBg2 {
    background-color: rgba(0, 0, 0, 0.4);

}

.homeBannerBg3 {
    background-color: rgba(0, 0, 0, 0.6);
}

